import { useStaticQuery, graphql } from 'gatsby';
import { adaptPages } from '@src/adapters/page';
import { PageData } from '@types';

export const usePageList = (): PageData[] => {
  const { pages } = useStaticQuery(
    graphql`
      query PageList {
        pages: allMarkdownRemark(
          filter: { fields: { collection: { eq: "page" } } }
        ) {
          edges {
            node {
              frontmatter {
                id
                image {
                  childImageSharp {
                    fluid(quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
  );
  return adaptPages(pages);
};
