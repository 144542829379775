import {
  GatsbyImageSharpFluid_WithWebpFragment,
  MarkdownRemarkFrontmatter,
  PageData,
} from '@types';
import { adaptImage } from './image';

export const adaptPage = (
  data:
    | {
        frontmatter?:
          | (Pick<MarkdownRemarkFrontmatter, 'id'> & {
              image?:
                | {
                    childImageSharp?:
                      | {
                          fluid?:
                            | GatsbyImageSharpFluid_WithWebpFragment
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            })
          | null
          | undefined;
      }
    | null
    | undefined,
): PageData | null => {
  if (data?.frontmatter?.id) {
    return {
      id: data.frontmatter.id,
      image: adaptImage(data.frontmatter.image),
    };
  }
  return null;
};

export const adaptPages = (data: {
  edges: {
    node: {
      frontmatter?: MarkdownRemarkFrontmatter | null | undefined;
    };
  }[];
}): PageData[] => {
  return data.edges
    .map(e => adaptPage(e.node))
    .flatMap(e => (e !== null ? [e] : []));
};
